import styles from '../styles/footer.module.css';

function Footer() {
  return (
    <div>
      <div className={styles.footerLinks}>
        <a
          href="https://aakash-cr7.github.io/"
          target="_blank"
          rel="noreferrer"
        >
          V1
        </a>
      </div>
    </div>
  );
}

export default Footer;
