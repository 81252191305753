import {
  Custtap,
  Iiitd,
  Invitify,
  Linklyst,
  Zulip,
  GreMate,
  Github,
  Linkedin,
  Stackoverflow,
  Twitter,
  Youtube,
  Leetcode,
} from './images';

const work = {
  education: [
    {
      timePeriod: '2021 - 2023',
      company: 'Florida Tech',
      title: 'MS in Computer Information Science',
      description: "I am pursuing a Master's degree from Florida Tech.",
    },
    {
      timePeriod: '2013 - 2017',
      company: 'Guru Gobind Singh Indraprastha University',
      title: 'B.Tech in Electronics & Comm. Engg.',
      description: '',
    },
  ],
  experience: [
    {
      timePeriod: '2019 - 2021',
      company: 'Coding Ninjas',
      title: 'Software Engineer & Instructor',
      description:
        'Built the cheating detection system for Career Camp exams. I also created the React course which has more than 1000 enrollments.',
    },
    {
      timePeriod: '2018 - 2019',
      company: 'Goibibo-Makemytrip',
      title: 'Software Engineer',
      description:
        'I was in the Bangalore and finally the Gurgaon office where I worked in the "GIA" (chat-bot) vertical. I also wrote an internal tool from scratch for the "Car" vertical.',
    },
    {
      timePeriod: '2017 - 2018',
      company: 'VideoKen.com',
      title: 'Software Engineer',
      description:
        "Implemented the quiz system for the web app. Also built the videoken's AI player which was used by many organizations like NPTEL, ACM ICPC and more.",
    },
  ],
};

const about = {
  title: 'Aakash Tyagi',
  description:
    "👋 there! I am pursing a masters degree from Florida Tech. I love to build cool shit. Currently, I am working on a product called 'Mjolnir' that helps people to log their gym workouts efficiently. \n\n Feel free to contact me if you've any questions!",
  social: [
    {
      name: 'Linkedin',
      link: 'https://www.linkedin.com/in/aakashtyagi/',
      image: Linkedin,
    },
    {
      name: 'Github',
      link: 'https://github.com/aakash-cr7',
      image: Github,
    },
    {
      name: 'Stackoverflow',
      link: 'https://stackoverflow.com/users/3908902/aakash',
      image: Stackoverflow,
    },
    {
      name: 'Twitter',
      link: 'https://twitter.com/aakashtyagi07',
      image: Twitter,
    },
    {
      name: 'Youtube',
      link: 'https://www.youtube.com/channel/UCp-4-GL0HYOy2gzY4pSJTYA',
      image: Youtube,
    },
    {
      name: 'Leetcode',
      link: 'https://leetcode.com/aakashcr7/',
      image: Leetcode,
    },
  ],
};

const projects = [
  {
    title: 'LinkLyst',
    role: 'Creator',
    description:
      'A place to organize links efficiently and effectively. A user can create collections of links and share with friends.',
    image: Linklyst,
    link: 'https://linklyst.xyz',
  },
  {
    title: 'GreMate',
    role: 'Creator',
    description:
      'A place to learn the GRE vocabulary effectively. With a choice of a multiple decks, this flashcard based app helps the user to enhhance their vocabulary through visual aids, synonyms and more.',
    image: GreMate,
    link: '',
  },
  {
    title: 'Invitify',
    role: 'Creator',
    description:
      'A Google Chrome extension to invite all your Facebook friends to like any Facebook page. Built back in the day when facebook did not have this feature.',
    image: Invitify,
    link: 'http://aakash-cr7.github.io/invitify/',
  },
  {
    title: 'IIIT Delhi Innovation',
    role: 'Intern',
    description:
      'Selected in IIIT Delhi\'s Innovation program. I built the frontend of our product called "Lobye", an app to find nearby businesses.',
    image: Iiitd,
    link: '',
  },
  {
    title: 'Zulip',
    role: 'Open source contributor',
    description:
      'Open source contributor for the organization. Mainly contributed on the Zulip web application. Also served as a Google Code In mentor for the organization.',
    image: Zulip,
    link: 'https://github.com/zulip/zulip',
  },
  {
    title: 'Custtap',
    role: 'Software Engineer Intern',
    description:
      'Worked as a full-stack engineer. Built the POS system and the school fee system from scratch.',
    image: Custtap,
    link: '',
  },
];

export { work, about, projects };
