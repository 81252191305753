import styles from '../styles/projects.module.css';
import { projects } from '../data';
import { LinkIcon } from '../images';

function Projects() {
  return (
    <div className={styles.projects} id="projects">
      <h2 className="block-header">Projects</h2>

      <div className={styles.projectsList}>
        {projects.map((project, index) => (
          <div className={styles.projectItem} key={`project-${index}`}>
            <img
              src={project.image}
              className={styles.projectImage}
              alt={project.title}
            />

            <h4 className={styles.projectTitle}>
              {project.title}
              {project.link ? (
                <a href={project.link} target="_blank" rel="noreferrer">
                  <img src={LinkIcon} alt="Project link" />
                </a>
              ) : null}
            </h4>
            <span className={styles.projectRole}>{project.role}</span>
            <p className={styles.projectDescription}>{project.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
